<div class="section pt-0 andro_fresh-arrivals category-section">
  <div class="container-fluid mt-6" style="padding: 0 20px;">
    <div class="products-wrapper mt-4">
      <div class="section-title flex-title">
        <h4 class="title">Advisory</h4>
      </div>

      <ng-container *ngIf="!productsList.length">
        <div class="container-fluid">
          <div class="loading">
            <img src="./assets/img/loading.gif" alt="loading">
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="productsList.length">
        <div class="row">
          <div class="col-md-12">
            <div *ngIf="productWrapper">
              <div class="row">
                <div class="col-md-2 col-sm-6" *ngFor="let item of productsList">
                  <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                    <div class="andro_product-thumb">
                      <a href="javascript:;" (click)="goToProdcutDetail(item)">
                        <img [lazyLoad]="item?.ImageUrl" alt="product"></a>
                    </div>
                    <div class="andro_product-body">
                      <h6 class="andro_product-title"> <a href="javascript:;" (click)="goToProdcutDetail(item)">
                          {{item?.CropName}} | {{item?.CropNameHindi}} </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center" *ngIf="!productWrapper">
              Products not found
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
