<!-- Subheader Start -->
<div class="andro_subheader pattern-bgpp primary-bg">
    <div class="container">
        <div class="andro_subheader-inner">
            <h1></h1>
            <nav aria-label="breadcrumb" class="mt-3">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<!-- Img Start -->
<div class="section">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-12">
                <div class="section-title-wrap mr-lg-30">
                <p class="custom-p"><strong><u><span class="custom-10">Behtar Zindagi Privacy Policy</span></u></strong></p>
                <p class="custom-span"><span class="custom-1">&nbsp;</span></p>
                <p class="custom-span"><span class="custom-1">THIS PRIVACY POLICY IS AN ELECTRONIC RECORD IN THE FORM OF ELECTRONIC CONTRACT IN TERMS OF INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE THEREUNDER (AS AMENDED FROM TIME TO TIME) AND DOES NOT REQUIRE ANY PHYSICAL SIGNATURE OR SEAL.</span></p>
                <p class="custom-span"><span class="custom-1">This Privacy Policy is applicable to&nbsp;</span><a href="https://behtarzindagi.in"><span class="custom-1">www.behtarzindagi.in</span></a><span class="custom-1">. The domain name&nbsp;</span><a href="https://www.behtarzindagi.in"><span class="custom-1">www.behtarzindagi.in</span></a><span class="custom-1">&nbsp;is owned by&nbsp;</span><a href="javascript%3Avoid();" title="FarmJunction Marketing Private Limited"><span style='font-size:16px;font-family:"Times New Roman",serif;color:#172337;'>Behtar Zindagi Private Limited</span></a><span class="custom-1">, a company incorporated under the Companies Act, 2013 with its registered office at 405 Ansal Bhawan, 16 Kasturba Gandhi Marg, New Delhi-110001</span></p>
                <p class="custom-span"><span class="custom-1">The term &ldquo;We&rdquo;/ &ldquo;Us&rdquo; / &ldquo;Our&rdquo; used in this document refer to Behtar Zindagi and &quot;You&quot; / &quot;Your&quot; / &quot;Yourself&quot; refer to the users, who visit or access or use or avail any service or product (collectively &ldquo;usage&rdquo;) on or through website/mobile site/App (individually and collectively, &ldquo;Website&rdquo;).</span></p>
                <div class="custom-2">
                <p class="custom-3"><span class="custom-5">TERMS AND CONDITIONS</span></p>
                </div>
<p class="custom-span2"><span class="custom-11">Acknowledgment</span></p>
<p class="custom-span"><span class="custom-1">PLEASE READ THIS PRIVACY POLICY CAREFULLY. YOU INDICATE THAT YOU UNDERSTAND, AGREE AND CONSENT TO THIS PRIVACY POLICY. HENCE BY VISITING OUR WEBSITE OR BY USING / AVAILING ANY OF OUR SERVICE OR PRODUCT, YOU HEREBY GIVE YOUR UNCONDITIONAL CONSENT OR AGREEMENTS TO BEHTAR ZINDAGI AS PROVIDED UNDER SECTION 43A AND SECTION 72A OF INFORMATION TECHNOLOGY ACT, 2000 FOR COLLECTION, USE, STORAGE, PROCESSING AND TRANSFER AND DISCLOSURE OF YOUR INFORMATION. YOU ACKNOWLEDGE THAT YOU HAVE ALL LEGAL RIGHTS AND LAWFUL AUTHORITY TO SHARE THE INFORMATION WITH US AND FURTHER ACKNOWLEDGE THAT BY COLLECTING, SHARING, PROCESSING AND TRANSFERRING INFORMATION PROVIDED BY YOU, THIS SHALL NOT CAUSE ANY LOSS OR WRONGFUL GAIN TO YOU OR ANY OTHER PERSON. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS / USE OUR WEBSITE OR AVAIL ANY OF OUR SERVICE OR PRODUCT ON OUR WEBSITE.</span></p>
<p class="custom-span2"><span class="custom-11">Information We Collect (Your Information) and store:</span></p>
<p class="custom-span"><span class="custom-1">We collect Your information during your usage to Website or when you avail any of services or products available on Website, either as a registered user or otherwise. The information collected may consists of:</span></p>
<p class="custom-span"><span class="custom-1">Your Personal Information including Your name, age, address, email id, phone number, date of birth, gender, address, any other sensitive personal data or information etc. We may receive Your Information from third parties such as social media, and in such case, the information We collect may include your user name associated with that social media, any information or content the social media has the right to share with us, such as your profile picture, email address or friends list, and any information you have made public in connection with that social media. When you access the Website or otherwise deal with any Behtar Zindagi entity through social media, you are authorizing Behtar Zindagi to collect, store, use and retain such information and content in accordance with the terms of this Privacy Policy.</span></p>
<p class="custom-span"><span class="custom-1">Your Information will mostly be stored in electronic form however certain data may be stored in physical form. We may store, collect, use and process Your Information in countries other than Republic of India subject to compliance under applicable laws. We may enter into agreements with third parties (in or outside of India) to store or process Your Information and such third parties may have their own security measures to safeguard Your Information.&nbsp;</span></p>
<div class="custom-2">
    <p class="custom-3"><span class="custom-5">Purpose of Collection, Storage, Processing:</span></p>
</div>
<p class="custom-span"><span class="custom-1">Behtar Zindagi collects Your Information solely for the purpose of providing you the product or service that is connected with the function or activity of Behtar Zindagi which includes but not limited to the following (&ldquo;Purpose&rdquo;):</span></p>
<ul class="custom-6">
    <li><span class="custom-4">To inform You about various products and services offered on Our Website.</span></li>
    <li><span class="custom-4">To address Your queries and resolve your concerns pertaining to any service or product that you were looking for.</span></li>
    <li><span class="custom-4">To send you survey and marketing communications or facilitate various programmes and initiatives launched either by Us or third party which We believes may be of interest to You.</span></li>
    <li><span class="custom-4">To facilitate Your usage of Website and improve Our Service, product or content on Website To review Website performance and do data analysis to make Your experience better.</span></li>
    <li><span class="custom-4">To protect the integrity of Our Website.</span></li>
    <li><span class="custom-4">To inform You about change in any term and condition of this Privacy Policy or Terms of Use of any Website of Behtar Zindagi</span></li>
    <li><span class="custom-4">To implement information security practices, to determine any security breaches, contaminant or computer virus, to investigate/prevent/take action illegal activities and suspected fraud.</span></li>
</ul>
<div class="custom-2">
    <p class="custom-3"><span class="custom-5">Sharing, Transfer or Disclosure:</span></p>
</div>
<p class="custom-span"><span class="custom-1">Any service or product provided through Our Website may be provided either by Behtar Zindagi or its vendor, dealer, OEM, channel partners and other third parties (&ldquo;other entities&rdquo;) which are registered with Behtar Zindagi to render the services or provide products through Website.</span></p>
<p class="custom-span"><span class="custom-1">You acknowledge and agree that Behtar Zindagi may share, disclose, transfer or part with Your Information to other entities depending upon the type of service or product You are looking for or for one or more of the permissible purposes. Behtar Zindagi may also share Your personal or non-personal information where it is required to share the same with such other entities for providing you the service or product and You provide Behtar Zindagi, Your unconditional consent to do the same.</span></p>
<p class="custom-span"><span class="custom-1">Behtar Zindagi may share statistical data and/or other Non-personal Information or details without your express or implied consent to facilitate various program or initiatives launched by Behtar Zindagi, third party service providers, partners or financial institutions, from time to time.</span></p>
<p class="custom-span"><span class="custom-1">In addition to this, Behtar Zindagi reserve the right to share Your Information with any Government Agency or other authorized law enforcement agencies (LEAs) mandated under law to obtain Your Information for the purpose of verification of identity or for prevention, detection, investigation including but not limited to cyber incidents, prosecution, and punishment of offences etc.</span></p>
<div class="custom-2">
    <p class="custom-3"><span class="custom-5">Third Party Links:</span></p>
</div>
<p class="custom-span"><span class="custom-1">It is clarified that while Your usage to Our Website, you might see the links to the third party website / advertisements / electronic communication service, which are provided by the third parties. As the operation of the third party is not under control, therefore Behtar Zindagi does not make any endorsement / guarantee of any service or product neither offered through third party websites nor makes any representation related to the any privacy policy or other policies of such third party. Any usage of such third party website or availing any service or product through third party shall be at Your risk and Behtar Zindagi is not responsible for any loss / damage or otherwise.</span></p>
<div class="custom-2">
    <p class="custom-3"><span class="custom-5">Security Measures</span></p>
</div>
<p class="custom-span"><span class="custom-1">Behtar Zindagi takes utmost care to secure Your Information from unauthorised access, usage or disclosure or alteration. We take appropriate steps and security measures to safeguard Your Information and make sure that Your Information is secured as mandated by law. For this purpose we adopt reasonable security practices and procedures, in line with the industry standard, to include, technical, operational, managerial and physical security controls in order to protect your personal information from unauthorized access, or disclosure while it is under our control.</span></p>
<p class="custom-span"><span class="custom-1">While We protect Your Personal information as per industry standards, You acknowledge that the internet or computer networks are not fully secure and that We cannot provide any absolute assurance regarding the security of Your Personal information. Therefore You acknowledge that You shall not hold Behtar Zindagi responsible in any manner whatsoever for loss of Your Information, or any other data, which You shares with Us or which We have with Us if such loss or any damage caused to You because of Force Majeure events. You further acknowledge that any information (including your personal information) transmitted to Us or from Your use of Website will therefore be at Your own risk.</span></p>
<p class="custom-span"><span class="custom-1">It is clarified that Force Majeure events includes any event which is beyond the reasonable control of the Behtar Zindagi which may include but not limited to sabotage, fire, flood, explosion, acts of God, civil commotion, strikes or industrial action of any kind, riots, insurrection, war, acts of government, computer hacking, unauthorised access to computer, computer system or computer network, computer crashes, breach of security and encryption.</span></p>
<div class="custom-2">
    <p class="custom-3"><span class="custom-5">Update Your Information</span></p>
</div>
<p class="custom-span"><span class="custom-1">Behtar Zindagi take all endeavors to update Our records with latest information as provided by you, however if you see any discrepancy in your personal information you may contact to Us through Grievance Officer to have Your Information updated with Us.</span></p>
<p class="custom-span"><span class="custom-1">You also acknowledge that all the information provided by You are out of Your free will and in compliance with applicable laws in Your territory. If We found that any of Your Information is in non-compliance with the terms and conditions and Privacy policy, Behtar Zindagi reserves the right to remove your non-compliant information from its systems. Further, depending on the seriousness of the non-compliance, We may choose to discontinue some or all of the services being provided to You by Us.&nbsp;</span></p>
<div class="custom-2">
    <p class="custom-3"><span class="custom-5">Authorization:</span></p>
</div>
<p class="custom-span"><span class="custom-1">In purview of the Telecom Regulatory Authority of India (TRAI) guidelines, You hereby authorize Behtar Zindagi and its affiliates/partners or otherwise who are accessing the Your Information by virtue of their association with Behtar Zindagi to communicate with You through telephone/mobile, Email, SMS or other modes of communication even if Yours number/numbers(s) is/are registered in the National Do Not Call Registry (NDNC) or www.nccptrai.gov.in&nbsp;</span></p>
<div class="custom-2">
    <p class="custom-3"><span class="custom-5">Change in terms of Privacy Policy:</span></p>
</div>
<p class="custom-span"><span class="custom-1">Behtar Zindagi reserves the right to amend or modify this Privacy Policy at any time, as and when the need arises. We request you to regularly check this Privacy Policy from time to time to keep you apprise of changes made. Your continued use of the Website gives your unconditional acceptance to such change in terms of Privacy Policy.</span></p>
<p class="custom-span"><span class="custom-1">If you have any concern related to this Privacy Policy or collection, storage, retention or disclosure of Your Information under the terms of this Privacy Policy or Terms of Use or any other terms and conditions of Behtar Zindagi or its other business entities including any queries or grievances, You can contact to Behtar Zindagi through its Grievance Redressal Officer at the below given details:&nbsp;</span></p>
<p class="custom-7"><span class="custom-8">Mr. Shekhar Chandran&nbsp;</span></p>
<p class="custom-7"><span class="custom-8">shekhar@behtarzindagi.in</span></p>
<p class="custom-7"><span class="custom-8">Monday to Friday&nbsp;</span></p>
<p class="custom-9"><span class="custom-8">10:30am to 6:30pm&nbsp;</span></p>
<p class="custom-span"><span class="custom-1">We will strive to address your feedback and concerns in a timely and effective manner. Please note that the details of the grievance officer may be changed by Us from time to time by updating this Privacy Policy.&nbsp;</span></p>
                 </div>
            </div>
        </div>
    </div>
</div>
<!-- Img End -->

