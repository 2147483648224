import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { Router, ActivatedRoute, NavigationEnd, RouterEvent } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { GlobalService } from 'src/app/services/global.service';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-sub-sub-categories',
  templateUrl: './sub-sub-categories.component.html',
  styleUrls: ['./sub-sub-categories.component.css']
})
export class SubSubCategoriesComponent implements OnInit {
  productsList: any = [];
  productWrapper: boolean = true;
  filterData: any = [];
  filterWrapper: boolean = true;
  subcategoryId: any;
  productCategoryTitle: any = '';
  subCategoryTitle: any = ''
  latLong: any;
  state: any;
  categoryId: any;
  loginStatus: boolean = false;
  constructor(
    private categoryService: CategoryService,
    private commonService: CommonService,
    private globalService: GlobalService,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private metaService: MetaService,
    private checkoutService: CheckoutService,
    private toastr: ToastrService,
  ) {
    this.globalService.defaultLocationObj.subscribe((res: { [x: string]: any; }) => this.state = res["state"])
    this.route.queryParams.subscribe(res => this.subCategoryTitle = res["subCategoryName"])
    this.route.queryParams.subscribe(res => this.categoryId = res["categoryId"])
    this.route.queryParams.subscribe(res => this.productCategoryTitle = res["Categoryname"])

  }

  ngOnInit(): void {
    this.commonService.getLatLongMessage().subscribe((res: any) => {
      if (res) {
        this.latLong = res;
      }
    })

    const loginStatus = localStorage.getItem("loginStatus");
    if (loginStatus == 'true') {
      this.loginStatus = true;
    }
    else {
      this.loginStatus = false;
    }
    // console.log("productCategoryTitle :: ", this.productCategoryTitle, this.state, this.subCategoryTitle)

    this.route.params.subscribe((res: any) => {
      if (res && res.subcategoryId) {
        // this.globalService.onSelectCategoryClick(res.subcategoryId);
        this.subcategoryId = res.subcategoryId;
        // const kgpStatus = localStorage.getItem('kgpStatus');
        // if (kgpStatus == '1') {
        //   this.subCategoryWrapper = true;
        //   this.getSubCategoriesList();
        // } else {
        //   this.subCategoryWrapper = false;
        //   const haryanaDistrictId = localStorage.getItem('haryanaDistrictId');
        //   this.getHaryanaProducts(this.categoryId, haryanaDistrictId);
        //   this.getSubCategoriesFilter(this.categoryId);
        // }
        this.getSubCategoriesProducts(this.subcategoryId);
        this.getSubCategoriesFilter(this.subcategoryId);
      }
    });

    // this.metaService.updateMeta('/bz/category', this.categoryId);
    // this.router.events.subscribe((event) => {
    //   if(event  instanceof NavigationEnd)
    //     this.metaService.updateMeta('/bz/category', this.categoryId);
    //   })
  }

  goToEnquiry(id: number, typeId: number, categoryId?: number, dealerId?: number) {
    let obj: any = {};
    obj["id"] = id
    obj["typeId"] = typeId
    obj["categoyId"] = categoryId
    obj["dealerId"] = dealerId
    this.globalService.onProductEnquiryClick(obj);
  }

  getSubCategoriesProducts(id: any) {
    this.productsList = [];
    this.categoryService.getSubCategoryProduct(id).subscribe(
      (res: any) => {
        if (res.ProductsApiReponse.Product.length > 0) {
          this.productWrapper = true;
          this.productsList = res.ProductsApiReponse.Product;
        } else {
          this.productWrapper = false;
        }
      }, (err: any) => console.log(err))
  }

  getSubCategoriesFilter(id: any) {
    this.filterData = [];
    this.categoryService.getSubCategoriesFilter(id).subscribe((res: any) => {
      if (res.KGPApiReponse) {
        this.filterWrapper = true;
        this.filterData = res.KGPApiReponse;
      } else {
        this.filterWrapper = false;
      }
    }, (err: any) => console.log(err))
  }

  goToProdcutDetail(data: { ProductName: any; RecordID: any; }) {
    if (data) {
      if (this.latLong) {
        this.commonService.sendLatLongMessage(this.latLong);
        localStorage.setItem('latData', this.latLong.lat);
        localStorage.setItem('longData', this.latLong.long);
      }
      let qString: any = { queryParams: { category: this.productCategoryTitle, name: data.ProductName } };
      if (this.subCategoryTitle != null && this.subCategoryTitle != '') {
        qString.queryParams["subcategory"] = this.subCategoryTitle;
      }
      this.router.navigate(['/bz/product-detail', data.RecordID], qString);
    }
  }

  notifyMe(packageID: any, recordID: any) {
    const farmerID = localStorage.getItem("FarmerId");
    this.checkoutService.notifyProduct(packageID, recordID, farmerID).subscribe((res: any) => {
      this.toastr.success(res.BZReponse.SaveOutofStockNotification[0].Msg, 'Success!!');
    }, (err: any) => {
      console.log(err);
    })
  }

}
