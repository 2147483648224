<div class="login_wrapper">
    <div class="modal_header">
        <h5 class="modal-title">{{callingFrom}}</h5>
        <button [mat-dialog-close]="false" cdkFocusInitial type="button" class="close" data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div>
        <form>
            <div class="locationForm mt-3">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="lble_flx"><span class="lbl">Mobile No.</span> <span *ngIf="showHint"
                                    class="hint">Enter 10 digit mobile number without country code or
                                    zero.</span></label>
                            <input class="form-control custom-input-field" maxlength="10" placeholder="Phone Number"
                                formControlName="mob" (keypress)="keyPressEvent($event)"
                                (blur)="onNumber($event);showHint = false"
                                 (focus)="showHint = true">
                            <div class="w-100 text-danger" *ngIf="errortoggle">
                                <small>Please enter valid 10 digit number.</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <button type="button" 
                            (click)="onLoginSubmit()"
                             class="btn btn-success">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>