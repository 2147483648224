import { Injectable } from '@angular/core';
import { API_PATH } from '../utilis/app.config';

import { Subject } from "rxjs";
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authStatusListener = new Subject<boolean>();
  private isAuthenticated = false;
  constructor(
    private http: HttpClient,
  ) { }




   getOTP(farmerName: string,mobileNo: string){
    return this.http.get(API_PATH.centraliseLogin + 'Name='+farmerName+ '&Number=' + mobileNo + '&FromSource=1&ToSource=0')
  }


  verfifyOTP(farmer: string,  mobileNo: string, otp: string){
    return this.http.get(API_PATH.centraliseVerifyLogin+ 'Name='+farmer+ '&Number=' + mobileNo + '&OTP=' + otp)
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  farmerAppLogin(phoneNo: string, referBy: string | number, farmerId: string | number, deviceId: string | number, farmerName: string){
    return this.http.get(API_PATH.farmerAppLogin + '?apiKey=123&PhoneNo=' + phoneNo + '&referedBy=' + referBy + '&FarmerId=' + farmerId + '&DeviceId=' + deviceId + '&Name=' + farmerName);
  }

  getFarmerId(mobileNo: string){
    return this.http.get(API_PATH.farmerIdUrl+ mobileNo)
  }

  logout() {
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
  }
}
