<div class="section pt-0 andro_fresh-arrivals category-section">
  <div class="container-fluid mt-2">
    <div class="section-title flex-title pb-0">
      <h4 class="title float-left">Brand Products </h4>
      <nav aria-label="breadcrumb" class="float-right">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:;" [routerLink]="['/']">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Products</li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="container-fluid mt-6" style="padding: 0 20px;">
    <div class="products-wrapper mt-4">
      <ng-container *ngIf="!brandsProduct.length">
        <div class="container-fluid">
          <div class="loading">
            <img src="./assets/img/loading.gif" alt="loading">
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="brandsProduct.length"> 
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-3 col-sm-6" *ngFor="let item of brandsProduct">
                <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                  <div class="andro_product-thumb">
                    <a href="javascript:;" (click)="goToProdcutDetail(item)"><img src="{{item?.ImagePath}}"
                        alt="product"></a>
                  </div>
                  <div class="andro_product-badge andro_badge-sale">
                    {{item?.OfferDiscount}}
                  </div>
                  <div class="andro_product-body">
                    <h6 class="andro_product-title"> <a href="javascript:;" (click)="goToProdcutDetail(item)">
                        {{item?.BrandName}} </a>
                    </h6>
                  </div>
                  <div class="andro_product-footer">
                    <div class="andro_product-price">
                      <p>
                        <span class="float-left">
                          <span class="offer-price">&#8377; {{item?.OnlinePrice}}</span>&nbsp;
                          <span class="main-price">&#8377; {{item?.MRP}}</span>
                        </span>
                        <span class="float-right pcs">{{item?.UnitName}}</span>
                      </p>
                    </div>
                    <div class="andro_product-buttons">
                      &nbsp;
                    </div>
                  </div>
                  <div class="andro_product-footer">
                    <div class="andro_product-price">
                         <span class="float-left">
                           <button type="button" (click)="goToProdcutDetail(item)" class="btn btn-success btn-sm">BUY NOW</button>      
                         </span>
                         <span class="float-right"><button type="button" (click)="goToEnquiry(item?.RecordID,1, item?.CategoryID, item?.DealerId)" class="btn btn-info btn-sm">ENQUIRY</button></span>
                       </div>
                     <div class="andro_product-buttons"></div>
                   </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>