<!-- Subheader Start -->
<div class="andro_subheader pattern-bgrp primary-bg">
    <div class="container">
        <div class="andro_subheader-inner">
            <h1></h1>
            <nav aria-label="breadcrumb" class="mt-3">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Refund Policy</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<!-- Img Start -->
<div class="section">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-12">
                <div class="section-title-wrap mr-lg-30">
                    <p>
                        <strong class="custom-10">REFUND POLICY</strong>
                    </p>
                    <p>
                        BehtarZindagi.in’s Return and Exchange Policy offers no return of goods
                        once delivered. If you choose to exchange the item for reason of mismatch
                        of product or receipt of a defective or expired item, you will be provided
                        with a replacement of the item, free of cost. However an exchange is
                        subject to availability of the product in our stock.
                        <br/>
                        <br/>
                        The following EXCEPTIONS and RULES apply to this Policy:
                        <br/>
                        1. All items to be returned or exchanged must be unused and in their
                        original condition with all original labels and packaging intact, and
                        should not be broken or tampered with.
                        <br/>
                        2. Exchanges are allowed only if your address is serviceable for an
                        Exchange by our logistics team.
                        <br/>
                        3. In case you had purchased a product which has a free gift/offer
                        associated with it and you wish to return the item, maximum of Refunds/Free
                        item MRP will be debited until the satisfactory receipt of all free
                        gift(s)/offer item(s) that are shipped along with it.
                        <br/>
                        4. If you choose to self-ship your returns, kindly pack the items securely
                        to prevent any loss or damage during transit. For all self-shipped returns,
                        we recommend you use a reliable courier service.
                        <br/>
                        5. Perishable goods such as seeds,plants and flowers cannot be returned, if
                        the packet is opened or damaged.
                        <br/>
                        6. Products must be returned within 07 days from the day of delivery, To
                        return your purchase, please call customer care at +91-7876400500
                        <br/>
                        To complete your return, we require a receipt or proof of purchase.
                        <br/>
                        Please do not send your purchase back to the manufacturer.
                        <br/>
                        <br/>
                        Refunds (if applicable)
                        <br/>
                        Once your return is received and inspected, we will send you an email to
                        notify you that we have received your returned item. We will also notify
                        you of the approval or rejection of your refund.
                        <br/>
                        If you are approved, then your refund will be processed, and a credit will
                        automatically be applied to your credit card or original method of payment,
                        within a 7 to 10 Working days.
                        <br/>
                        <br/>
                        Late or missing refunds (if applicable)
                        <br/>
                        If you haven’t received a refund yet, first check your bank account again.
                        <br/>
                        Then contact your credit card company, it may take some time before your
                        refund is officially posted.
                        <br/>
                        Next contact your bank. There is often some processing time before a refund
                        is posted.
                        <br/>
                        If you’ve done all of this and you still have not received your refund yet,
                        please contact us at info@behtarzindagi.in
                        <br/>
                        <br/>
                        Exchanges (if applicable)
                        <br/>
                        We only replace items if they are defective or damaged. If you need to
                        exchange it for the same item, send us an email at support@behtarzindagi.in
                        and send your item to: <br/>Behtar Zindagi Pvt Ltd, #, 405 Ansal Bhawan, 16 K.G<br/>
                        Marg, New Delhi – 110001
                        <br/>
                        <br/>
                        Governing Law:
                        <br/>
                        These terms shall be governed by and constructed in accordance with the
                        laws of India without reference to conflict of laws principles and disputes
                        arising in relation hereto shall be subject to the exclusive jurisdiction
                        of the courts at Bangalore, Karnataka.
                        <br/>
                        <br/>
                        Shipping
                        <br/>
                        To return your product, you should mail your product to:
                        <br/>
                        Behtar Zindagi Pvt Ltd, 405 Ansal Bhawan, 16 K.G Marg, New Delhi –<br/>
                        110001.<br/>You will be responsible for paying for your own shipping costs for
                        returning your item. Shipping costs are non-refundable. If you receive a
                        refund, the cost of return shipping will be deducted from your refund.
                        <br/>
                        <br/>
                        Depending on where you live, the time it may take for your exchanged
                        product to reach you, may vary.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Img End -->
