import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { GlobalService } from 'src/app/services/global.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  subCategoryList: any = [];
  categoryId: any
  firstCategoryId: any
  productsList: any = [];

  selectedIndex: number = 0;
  filterData: any = [];
  subCategoryWrapper: boolean = true;
  filterWrapper: boolean = true;
  productWrapper: boolean = true;
  state: any;
  district: any
  latLong: any
  productCategoryTitle: any = '';
  subCategoryTitle: any = ''
  id: string = '';
  name: string = '';
  category: number = 0;
  farmerId: number = 0;
  searchValue: any = '';
  loaderToggle: boolean = false;
  loginStatus: boolean = false;
  constructor(
    private categoryService: CategoryService,
    private commonService: CommonService,
    private globalService: GlobalService,
    private router: Router,
    private route: ActivatedRoute,
    private checkoutService: CheckoutService,
    private toastr: ToastrService,
  ) {


    this.globalService.defaultLocationObj.subscribe((res: { [x: string]: any; }) => this.state = res["state"])
    this.route.queryParams.subscribe(res => {
      if (res['searchType'] == "raw") {
        this.searchValue = res["searchValue"];
        this.getSearchProductsByRawData(this.searchValue);
      }
      else {
        this.id = res["id"];
        this.name = res["name"];
        this.category = res["category"];
        if (localStorage.getItem("FarmerId") != undefined) {
          this.farmerId = Number(localStorage.getItem("FarmerId"));
        }
        this.getSearchProducts(this.id, this.name, this.category, this.farmerId);
      }
    })
  }

  goToEnquiry(id: number, typeId: number, categoryId?: number, dealerId?: number) {
    let obj: any = {};
    obj["id"] = id
    obj["typeId"] = typeId
    obj["categoyId"] = categoryId
    obj["dealerId"] = dealerId
    this.globalService.onProductEnquiryClick(obj);
  }
  ngOnInit(): void {
    const loginStatus = localStorage.getItem("loginStatus");
    if (loginStatus == 'true') {
      this.loginStatus = true;
    }
    else {
      this.loginStatus = false;
    }
    this.commonService.getLatLongMessage().subscribe((res: any) => {
      if (res) {
        this.latLong = res;
      }
    })
  }

  getSearchProducts(id: string, name: string, categoryId: number, farmerId: number) {
    console.log('search fired', id, name, categoryId, farmerId);
    this.loaderToggle = true;
    this.productsList = [];
    this.categoryService.productDtlSearch(id, name, categoryId, farmerId).subscribe(
      (res: any) => {
        console.log("res 11 :: ", res)
        if (res.BZApiResponse.length > 0) {
          this.productWrapper = true;
          this.productsList = res.BZApiResponse;
          this.loaderToggle = false;
        } else {
          this.productWrapper = false;
          this.loaderToggle = false;
        }
      }, (err: any) => console.log(err))
  }

  getSearchProductsByRawData(value: string) {
    this.productsList = [];
    this.loaderToggle = true;
    let mobileNo = ''
    if (localStorage.getItem("farmerMob")) {
      mobileNo = localStorage.getItem("farmerMob")!
    }
    this.categoryService.productbySearchValue(value, mobileNo).subscribe(
      (res: any) => {
        console.log("res :: ", res.Status)
        if (res.Status) {
          this.productWrapper = true;
          this.productsList = res.ProductsApiReponse['Product'];
          this.loaderToggle = false;
        } else {
          this.productWrapper = false;
          this.loaderToggle = false;
        }
      }, (err: any) => console.log(err))

  }

  setIndex(index: number) {
    this.selectedIndex = index;
  }

  getSubCategoriesList() {
    this.categoryService.getSubCategory(this.categoryId).subscribe((res: any) => {
      this.subCategoryList = res.BZAppSubCatagory.BZFarmerAppSubCatagory;
      this.firstCategoryId = this.subCategoryList[0].CategoryID;
      this.subCategoryTitle = this.subCategoryList[0].CategoryName;
      if (this.firstCategoryId) {
        this.getSubCategoriesProducts(this.firstCategoryId);
        this.getSubCategoriesFilter(this.firstCategoryId);
      }

    }, (err: any) => {
      console.log(err);
    })
  }

  goToSubcategory(item: { CategoryName: any; CategoryID: any; }) {
    this.subCategoryTitle = item.CategoryName;
    this.getSubCategoriesProducts(item.CategoryID);
    this.getSubCategoriesFilter(item.CategoryID);
  }


  getSubCategoriesProducts(id: any) {
    this.productsList = [];
    this.loaderToggle = true;
    this.categoryService.getSubCategoryProduct(id).subscribe(
      (res: any) => {
        if (res.ProductsApiReponse.Product.length > 0) {
          this.productWrapper = true;
          this.productsList = res.ProductsApiReponse.Product;
          this.loaderToggle = false;
        } else {
          this.productWrapper = false;
          this.loaderToggle = false;
        }
      }, (err: any) => console.log(err))
  }

  getHaryanaProducts(catId: any, districtId: any) {
    this.categoryService.getHaryanCategoryProducts(catId, districtId).subscribe((res: any) => {
      if (res.Status) {
        this.productsList = res.ProductsApiReponse.Product;
      }
    }, (err: any) => console.log(err))
  }

  goToProdcutDetail(data: { ProductName: any; RecordID: any; }) {
    if (data) {
      if (this.latLong) {
        this.commonService.sendLatLongMessage(this.latLong);
        localStorage.setItem('latData', this.latLong.lat);
        localStorage.setItem('longData', this.latLong.long);
      }
      let qString: any = { queryParams: { category: this.productCategoryTitle, name: data.ProductName } };
      if (this.subCategoryTitle != null && this.subCategoryTitle != '') {
        qString.queryParams["subcategory"] = this.subCategoryTitle;
      }
      this.router.navigate(['/bz/product-detail', data.RecordID], qString);
    }
  }

  getSubCategoriesFilter(id: any) {
    this.filterData = [];
    this.categoryService.getSubCategoriesFilter(id).subscribe((res: any) => {
      if (res.KGPApiReponse) {
        this.filterWrapper = true;
        this.filterData = res.KGPApiReponse;
      } else {
        this.filterWrapper = false;
      }
    }, (err: any) => console.log(err))
  }

  notifyMe(packageID: any, recordID: any) {
    const farmerID = localStorage.getItem("FarmerId");
    this.checkoutService.notifyProduct(packageID, recordID, farmerID).subscribe((res: any) => {
      this.toastr.success(res.BZReponse.SaveOutofStockNotification[0].Msg, 'Success!!');
    }, (err: any) => {
      console.log(err);
    })
  }
}
