

<div class="modal-content">
    <div class="modal_header">
        <h5 class="modal-title">Alert!</h5>
        <button (click)="onClose()" type="button" class="close"  aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">{{data}}</div>
</div>


