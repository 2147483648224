import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';



@Component({
  selector: 'app-third-party',
  templateUrl: './third-party.component.html',
  styleUrls: ['./third-party.component.css']
})
export class ThirdPartyComponent implements OnInit {
  loginForm!: FormGroup;
  mobileNo: any;
  callingFrom: string;
  showHint: boolean = false;
  errortoggle: boolean = false;
  loginWrapperBox: boolean = true;
  farmerId:any;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) data: any,
    private toastr: ToastrService,
    private globalService: GlobalService,
  ) {
    this.callingFrom = data.callingFrom;
  }

  ngOnInit(): void {
    this.createLoginForm();
  }

  onLoginSubmit() {
    const mobileNo = this.mobileNo
    this.authService.getFarmerId(mobileNo).subscribe((res: any) => {
      this.farmerId = res.FarmerID;
      if ('third_party_flag' in sessionStorage && sessionStorage.getItem('third_party_flag') == '1'){
        localStorage.setItem('FarmerId', res.FarmerID);
      }
      this.getFarmerDetails(this.farmerId);
    }, (err: any) => console.log(err))

    console.log("onLoginSubmit")
  }

  getFarmerDetails(farmerId: any) {
    this.globalService.getExistingFarmerDetails(farmerId).subscribe((res: any) => {
      let details = res.BZAppFarmerAddress
      localStorage.setItem('farmer_detail_wol', JSON.stringify(details));
      this.loginWrapperBox = false;
      this.dialog.closeAll();
    }, err => console.log(err))
  }



  createLoginForm() {
    console.log("createLoginForm")
    this.loginForm = this.fb.group({
      mob: ['', [Validators.required, Validators.pattern('^[6789][0-9]{9}$')]]
    })
  }

  onNumber(event: any) {
    const value = event.target.value
    if (value.length < 10) {
      this.errortoggle = true
    } else {
      this.errortoggle = false;
    }
    this.mobileNo = value;
  }

  keyPressEvent(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

}
