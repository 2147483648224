<!-- Subheader Start -->
<div class="andro_subheader pattern-bgtc primary-bg">
    <div class="container">
        <div class="andro_subheader-inner">
            <h1></h1>
            <nav aria-label="breadcrumb" class="mt-3">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Terms & Conditions</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<!-- Img Start -->
<div class="section">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-12">
                <div class="section-title-wrap mr-lg-30">
                    <p>
                        <strong class="custom-10">TERMS OF SERVICE</strong>
                    </p>
                    <p>
                        Please read the following terms and conditions very carefully as your use
                        of service is subject to your acceptance of and compliance with the
                        following terms and conditions ("Terms").
                        <br />
                        <br />
                        By subscribing to or using any of our services you agree that you have
                        read, understood and are bound by the Terms, regardless of how you
                        subscribe to or use the services. If you do not want to be bound by the
                        Terms, you must not subscribe to or use our services. These Terms and
                        various other policies are binding as per the provisions of the Information
                        Technology (Intermediaries guidelines) Rules, 2011 formulated under the
                        Information Technology Act of 2000.
                        <br />
                        <br />
                        In these Terms, references to "you", "User" shall mean the end user
                        accessing the Website, its contents and using the Services offered through
                        the Website, "Service Providers" mean independent third party service
                        providers, and "we", "us" and "our" shall mean Vector E-commerce Private
                        Limited and its affiliates.
                        <br />
                        <br />
                        1. Introduction:
                        <br />
                        a) www.Behtar Zindagi.in ("Website") is an Internet based content and
                        e-commerce portal, a company incorporated under the laws of India.
                        <br />
                        b) Use of the Website is offered to you conditioned on acceptance without
                        modification of all the terms, conditions and notices contained in these
                        Terms, as may be posted on the Website from time to time. Behtar Zindagi.in
                        at its sole discretion reserves the right not to accept a User from
                        registering on the Website without assigning any reason thereof.
                        <br />
                        <br />
                        2. User Account, Password, and Security:
                        <br />
                        You will receive a password and account designation upon completing the
                        Website's registration process. You are responsible for maintaining the
                        confidentiality of the password and account, and are fully responsible for
                        all activities that occur under your password or account. You agree to (a)
                        immediately notify Behtar Zindagi.in of any unauthorized use of your
                        password or account or any other breach of security, and (b) ensure that
                        you exit from your account at the end of each session. Behtar Zindagi.in
                        cannot and will not be liable for any loss or damage arising from your
                        failure to comply with this Section 2.
                        <br />
                        <br />
                        3. Services Offered:
                        <br />
                        Behtar Zindagi.in provides a number of Internet-based services through the
                        Web Site (all such services, collectively, the "Service"). One such service
                        enables users to purchase original products such as seeds and accessories
                        from various supplier brands (collectively, "Products"). The Products can
                        be purchased through the Website through various methods of payments
                        offered. The sale/purchase of Products shall be additionally governed by
                        specific policies of sale, like cancellation policy, return policy, etc.
                        (which are found on the following URL http://www.Behtar Zindagi.in/faqs),
                        and all of which are incorporated here by reference. In addition, these
                        terms and policies may be further supplemented by Product specific
                        conditions, which may be displayed on the webpage of that Product.
                        <br />
                        <br />
                        4. Privacy Policy:
                        <br />
                        The User hereby consents, expresses and agrees that he has read and fully
                        understands the Privacy Policy of Behtar Zindagi.in. The user further
                        consents that the terms and contents of such Privacy Policy are acceptable
                        to him.
                        <br />
                        <br />
                        5. Limited User:
                        <br />
                        The User agrees and undertakes not to reverse engineer, modify, copy,
                        distribute, transmit, display, perform, reproduce, publish, license, create
                        derivative works from, transfer, or sell any information or software
                        obtained from the Website. Limited reproduction and copying of the content
                        of the Website is permitted provided that Behtar Zindagi’s name is stated
                        as the source and prior written permission of Behtar Zindagi.in is sought.
                        For the removal of doubt, it is clarified that unlimited or wholesale
                        reproduction, copying of the content for commercial or non-commercial
                        purposes and unwarranted modification of data and information within the
                        content of the Website is not permitted.
                        <br />
                        <br />
                        6. User Conduct and Rules:
                        <br />
                        You agree and undertake to use the Website and the Service only to post and
                        upload messages and material that are proper. By way of example, and not as
                        a limitation, you agree and undertake that when using a Service, you will
                        not:
                        <br />
                        (a) defame, abuse, harass, stalk, threaten or otherwise violate the legal
                        rights of others;
                        <br />
                        (b) publish, post, upload, distribute or disseminate any inappropriate,
                        profane, defamatory, infringing, obscene, indecent or unlawful topic, name,
                        material or information;
                        <br />
                        (c) upload files that contain software or other material protected by
                        intellectual property laws unless you own or control the rights thereto or
                        have received all necessary consents; you own or control the rights thereto
                        or have received all necessary consents;
                        <br />
                        (d) upload or distribute files that contain viruses, corrupted files, or
                        any other similar software or programs that may damage the operation of the
                        Website or another's computer;
                        <br />
                        (e) conduct or forward surveys, contests, pyramid schemes or chain letters;
                        <br />
                        (f) download any file posted by another user of a Service that you know, or
                        reasonably should know, cannot be legally distributed in such manner;
                        <br />
                        (g) falsify or delete any author attributions, legal or other proper
                        notices or proprietary designations or labels of the origin or source of
                        software or other material contained in a file that is uploaded;
                        <br />
                        (h) violate any code of conduct or other guidelines, which may be
                        applicable for or to any particular Service;
                        <br />
                        (i) violate any applicable laws or regulations for the time being in force
                        in or outside India; and
                        <br />
                        (j) violate, abuse, unethically manipulate or exploit, any of the terms and
                        conditions of this Agreement or any other terms and conditions for the use
                        of the Website contained elsewhere.
                        <br />
                        <br />
                        7. User Warranty and Representation:
                        <br />
                        The user guarantees, warrants, and certifies that you are the owner of the
                        content which you submit or otherwise authorised to use the content and
                        that the content does not infringe upon the property rights, intellectual
                        property rights or other rights of others. You further warrant that to your
                        knowledge, no action, suit, proceeding, or investigation has been
                        instituted or threatened relating to any content, including trademark,
                        trade name service mark, and copyright formerly or currently used by you in
                        connection with the Services rendered by Behtar Zindagi.in.
                        <br />
                        <br />
                        8. Exactness Not Guaranteed:
                        <br />
                        Behtar Zindagi.in hereby disclaims any guarantees of exactness as to the
                        finish and appearance of the final Product as ordered by the user. The
                        quality of any products, Services, information, or other material purchased
                        or obtained by you through the Website may not meet your expectations.
                        Alterations to certain aspects of your order such as the supplier brand,
                        quantity etc. may be required due to limitations caused by availability of
                        product difference in quantity charts of respective brands etc. In this
                        instance you agree that Behtar Zindagi.in will send an approval request via
                        the email address, which you submitted when placing your order. If you do
                        not agree with the requested change you retain the right to reject the
                        requested product change by replying to it within 10 days of it being sent
                        to you.
                        <br />
                        <br />
                        9. Intellectual Property Rights:
                        <br />
                        a) Unless otherwise indicated or anything contained to the contrary or any
                        proprietary material owned by a third party and so expressly mentioned,
                        Behtar Zindagi.in owns all Intellectual Property Rights to and into the
                        Website, including, without limitation, any and all rights, title and
                        interest in and to copyright, related rights, patents, utility models,
                        trademarks, trade names, service marks, designs, know-how, trade secrets
                        and inventions (whether patentable or not), goodwill, source code, meta
                        tags, databases, text, content, graphics, icons, and hyperlinks. You
                        acknowledge and agree that you shall not use, reproduce or distribute any
                        content from the Website belonging to Behtar Zindagi.in without obtaining
                        authorization from Behtar Zindagi.in.
                        <br />
                        b) Notwithstanding the foregoing, it is expressly clarified that you will
                        retain ownership and shall solely be responsible for any content that you
                        provide or upload when using any Service, including any text, data,
                        information, images, photographs, music, sound, video or any other material
                        which you may upload, transmit or store when making use of our various
                        Service. However, with regard to the product customization Service (as
                        against other Services like blogs and forums) you expressly agree that by
                        uploading and posting content on to the Website for public viewing and
                        reproduction/use of your content by third party users, you accept the User
                        whereby you grant a non-exclusive license for the use of the same.
                        <br />
                        <br />
                        10. Links To Third Party Sites:
                        <br />
                        The Website may contain links to other websites ("Linked Sites"). The
                        Linked Sites are not under the control of Behtar Zindagi.in or the Website
                        and Behtar Zindagi.in is not responsible for the contents of any Linked
                        Site, including without limitation any link contained in a Linked Site, or
                        any changes or updates to a Linked Site. Behtar Zindagi.in is not
                        responsible for any form of transmission, whatsoever, received by you from
                        any Linked Site. Behtar Zindagi.in is providing these links to you only as
                        a convenience, and the inclusion of any link does not imply endorsement by
                        Behtar Zindagi.in or the Website of the Linked Sites or any association
                        with its operators or owners including the legal heirs or assigns thereof.
                        The users are requested to verify the accuracy of all information on their
                        own before undertaking any reliance on such information.
                    </p>
                    <p>
                        <br />
                        11. Disclaimer Of Warranties/Limitation Of Liability:
                        <br />
                        a) Behtar Zindagi.in has endeavored to ensure that all the information on
                        the Website is correct, but Behtar Zindagi.in neither warrants nor makes
                        any representations regarding the quality, accuracy or completeness of any
                        data, information, product or Service. In no event shall Behtar Zindagi.in
                        be liable for any direct, indirect, punitive, incidental, special,
                        consequential damages or any other damages resulting from: (a) the use or
                        the inability to use the Services or Products; (b) unauthorized access to
                        or alteration of the user's transmissions or data; (c) any other matter
                        relating to the services; including, without limitation, damages for loss
                        of use, data or profits, arising out of or in any way connected with the
                        use or performance of the Website or Service. Neither shall Behtar
                        Zindagi.in be responsible for the delay or inability to use the Website or
                        related services, the provision of or failure to provide Services, or for
                        any information, software, products, services and related graphics obtained
                        through the Website, or otherwise arising out of the use of the website,
                        whether based on contract, tort, negligence, strict liability or otherwise.
                        Further, Behtar Zindagi.in shall not be held responsible for
                        non-availability of the Website during periodic maintenance operations or
                        any unplanned suspension of access to the website that may occur due to
                        technical reasons or for any reason beyond Behtar Zindagi’s control. The
                        user understands and agrees that any material and/or data downloaded or
                        otherwise obtained through the Website is done entirely at their own
                        discretion and risk and they will be solely responsible for any damage to
                        their computer systems or loss of data that results from the download of
                        such material and/or data.
                    </p>
                    <p>
                        b) The performance of the product is subject to usage as per manufacturer
                        guidelines. Read enclosed leaflet of the products carefully before use. The
                        use of this information is at the discretion of user.
                    </p>
                    <p>
                        <br />
                        12. Indemnification:
                        <br />
                        You agree to indemnify, defend and hold harmless Behtar Zindagi.in from and
                        against any and all losses, liabilities, claims, damages, costs and
                        expenses (including legal fees and disbursements in connection therewith
                        and interest chargeable thereon) asserted against or incurred by Behtar
                        Zindagi.in that arise out of, result from, or may be payable by virtue of,
                        any breach or non-performance of any representation, warranty, covenant or
                        agreement made or obligation to be performed by you pursuant to these
                        Terms.
                        <br />
                        <br />
                        13. Pricing:
                        <br />
                        Prices for products are described on our Website and are incorporated into
                        these Terms by reference. All prices are in Indian rupees. Prices, products
                        and Services may change at Behtar Zindagi’s discretion.
                        <br />
                        <br />
                        14. Shipping:
                        <br />
                        Title and risk of loss for all products ordered by you shall pass on to you
                        upon Behtar Zindagi’s shipment to the shipping carrier. Rules on COD vary
                        based on transaction value, products, shipping location and other relevant
                        parameters. Behtar Zindagi shall retain the right to offer/deny COD for
                        specific cases.
                        <br />
                        <br />
                        15. Termination:
                        <br />
                        a) Behtar Zindagi.in may suspend or terminate your use of the Website or
                        any Service if it believes, in its sole and absolute discretion that you
                        have breached, violated, abused, or unethically manipulated or exploited
                        any term of these Terms or anyway otherwise acted unethically.
                        <br />
                        b) Notwithstanding Section 15.a above, these Terms will survive
                        indefinitely unless and until Behtar Zindagi.in chooses to terminate them.
                        <br />
                        c) If you or Behtar Zindagi.in terminates your use of the Website or any
                        Service, Behtar Zindagi.in may delete any content or other materials
                        relating to your use of the Service and Behtar Zindagi.in will have no
                        liability to you or any third party for doing so.
                        <br />
                        d) You shall be liable to pay for any Service or product that you have
                        already ordered till the time of Termination by either party whatsoever.
                        Further, you shall be entitled to your royalty payments as per the User
                        License Agreement that has or is legally deemed accrued to you.
                        <br />
                        <br />
                        16. Governing Law:
                        <br />
                        These terms shall be governed by and constructed in accordance with the
                        laws of India without reference to conflict of laws principles and disputes
                        arising in relation hereto shall be subject to the exclusive jurisdiction
                        of the courts at Bangalore, Karnataka.
                        <br />
                        <br />
                        17. Severability:
                        <br />
                        If any provision of the Terms is determined to be invalid or unenforceable
                        in whole or in part, such invalidity or unenforceability shall attach only
                        to such provision or part of such provision and the remaining part of such
                        provision and all other provisions of these Terms shall continue to be in
                        full force and effect.
                        <br />
                        <br />
                        18. Report Abuse:
                        <br />
                        As per these Terms, users are solely responsible for every material or
                        content uploaded on to the Website. Users can be held legally liable for
                        their contents and may be held legally accountable if their contents or
                        material include, for example, defamatory comments or material protected by
                        copyright, trademark, etc. If you come across any abuse or violation of
                        these Terms, please report to support@behtarzindagi.in
                        <br />
                        <br />
                    </p>
                    19. Others:
                    <ul class="pl-4">
                        <li>Any order can be cancelled till the pick is done. Post pick up no cancellation will be
                            allowed</li>
                        <li>If cancellation is done before pickup, total amount will be refunded</li>
                        <li>There will be no refund post cancellation duration is crossed</li>
                        <li>As we are marketplace, we communicate SPl curated offers for BZ by seller to customer. BZ
                            only pass discount & financing & free accessories if applicable along with free logistics
                        </li>
                        <li>Only Product under Warranty will be replaced or repaired</li>
                        <li>BZ will not bear any cost for products not under warranty</li>
                        <li>In case of any damage during logistics, BZ will replace the defected item</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Img End -->