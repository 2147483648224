<!-- Subheader Start -->
<div class="andro_subheader pattern-bgcu primary-bg">
    <div class="container">
        <div class="andro_subheader-inner">
            <h1></h1>
            <nav aria-label="breadcrumb" class="mt-3">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<!-- Img Start -->
<div class="section">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-12">
                <div class="section-title-wrap mr-lg-30">
                    <p>
                        <strong class="custom-10">CONTACT US</strong>
                    </p>
                    <p>
                        <strong>REACH Behtar Zindagi:</strong>
                    </p>
                    <p>
                    By a missed call:    <a href="tel:180030002434"><strong>7876400500</strong></a><strong></strong>
                    </p>
                    <p>
                        <strong></strong>
                    </p>
                    <p>
                        <strong>By Whatsapp : 7876400500</strong>
                        <br/>
                        <br/>
                    Talk to us at customer care (8 AM - 5 PM from Monday - Saturday):    <strong>7876400500</strong>
                        <br/>
                        Email us: <strong>support@behtarzindagi.in</strong>
                    </p>
                    <p>
                        <strong>Head Office:</strong>
                    </p>
                    <p>
                        <strong>Delhi Office:</strong>
                    </p>
                    <p>
                        Behtar Zindagi Pvt Ltd.
                        <br/>
                        # 4<sup>th</sup> Floor, 405 Ansal Bhawan,
                        <br/>
                        16 Kasturba Gandhi Marg
                        <br/>
                        Connaught Place,Delhi
                        <br/>
                        Pin Code: 110001
                    </p>
                 </div>
            </div>
        </div>
    </div>
</div>
<!-- Img End -->
