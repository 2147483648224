import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { CategoryService } from 'src/app/services/category.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { GlobalService } from 'src/app/services/global.service';
import { LoginComponent } from '../../login/login.component';
import { MetaService } from 'src/app/services/meta.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/services/common.service';
import { take } from 'rxjs/operators';
import { CheckoutService } from 'src/app/services/checkout.service';
import { ToastrService } from 'ngx-toastr';
import { ThirdPartyComponent } from '../../third-party/third-party.component';


@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {
  productId: any;
  districtId: any;
  dealerDetails: any = [];
  productDetails: any = [];
  desc: any = [];
  productAvailability: boolean = true;
  dealersAvailability: boolean = true;
  dealersMessage!: string;
  globalBuyWrapper: boolean = true;
  dealerID: any;
  latData: any;
  ItemCategoryTitle: any = ''
  districtResult: any;
  farmerId: any;
  mobileNo: any;
  state: any;
  isManualLocation!: boolean;
  imageViewThumb: boolean = false;
  relatedProducts: any = [];
  loopComplete: boolean = false;
  currentSlideIndex: number = 5;
  packageId: any;
  latLong: any;
  loginStatus: boolean = false;

  slideConfig = {
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: false,
    autoplaySpeed: 5000,
    nextArrow: `<div class="slide_next"><i class="fa fa-arrow-left slick-arrow slider-next"></i></div>`,
    prevArrow: `<div class="slide_prev"><i class="fa fa-arrow-right slick-arrow slider-prev"></i></div>`,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  videoUrl: any = "https://www.youtube.com/embed/IIWOz77-LJ0?autoplay=0&mute=1&playsinline=1"

  constructor(
    private commonService: CommonService,
    private categoryService: CategoryService,
    private router: Router,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private location: Location,
    private metaService: MetaService,
    public sanitizer: DomSanitizer,
    private ref: ChangeDetectorRef,
    private checkoutService: CheckoutService,
    private toastr: ToastrService,
  ) {




    this.route.queryParams.subscribe(res => this.ItemCategoryTitle = res["category"])
    this.globalService.isManualLocation.subscribe((res: any) => this.isManualLocation = res);
    this.route.params.subscribe(res => this.productId = res["productId"]);
  }


  goToEnquiry(id: number, typeId: number, categoryId?: number, dealerId?: number) {
    let obj: any = {};
    obj["id"] = id
    obj["typeId"] = typeId
    obj["categoyId"] = categoryId
    obj["dealerId"] = dealerId
    this.globalService.onProductEnquiryClick(obj);
  }
  ngOnInit(): void {
    console.log("NG_ID")
    const loginStatus = localStorage.getItem("loginStatus");
    if (loginStatus == 'true') {
      this.loginStatus = true;
    }
    else {
      this.loginStatus = false;
    }
    this.metaService.updateMeta('/bz/product-detail', null);
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.getProductDetailsMaster();
      }
    });
    this.getProductDetailsMaster();
    // if (localStorage.getItem('lat') != null) {
    //   this.getProductDetailsMaster();
    // }
    /**SET LAT-LONG**/
    this.commonService.getLatLongMessage().pipe(take(1)).subscribe((res: any) => {
      if (res) {
        this.latLong = res;
      }
    })

  }


  magnify(imgID: string, zoom: number) {
    var img: any | null, glass: HTMLElement, w: number, h: number, bw: number;
    img = document.getElementById(imgID)!;
    /*create magnifier glass:*/
    glass = document.createElement("DIV");
    glass.setAttribute("class", "img-magnifier-glass");
    /*insert magnifier glass:*/
    //debugger
    img.parentElement.insertBefore(glass, img);
    /*set background properties for the magnifier glass:*/
    glass.style.backgroundImage = "url('" + img.src + "')";
    glass.style.backgroundRepeat = "no-repeat";
    glass.style.backgroundSize =
      img.width * zoom + "px " + img.height * zoom + "px";
    bw = 3;
    w = glass.offsetWidth / 2;
    h = glass.offsetHeight / 2;
    /*execute a function when someone moves the magnifier glass over the image:*/
    //glass.addEventListener("mousemove", moveMagnifier);
    img.addEventListener("mousemove", moveMagnifier);
    //img.addEventListener("mouseenter", showMagnifier(glass));
    img.addEventListener("mouseout", hideMagnifier);
    /*and also for touch screens:*/
    //glass.addEventListener("touchmove", moveMagnifier);
    //img.addEventListener("touchmove", moveMagnifier);
    function moveMagnifier(e: any) {

      glass.style.display = "block"
      var pos, x, y;
      /*prevent any other actions that may occur when moving over the image*/
      e.preventDefault();
      /*get the cursor's x and y positions:*/
      pos = getCursorPos(e);
      x = pos.x;
      y = pos.y;
      /*prevent the magnifier glass from being positioned outside the image:*/
      if (x > img.width - w / zoom) {
        x = img.width - w / zoom;
      }
      if (x < w / zoom) {
        x = w / zoom;
      }
      if (y > img.height - h / zoom) {
        y = img.height - h / zoom;
      }
      if (y < h / zoom) {
        y = h / zoom;
      }
      /*set the position of the magnifier glass:*/
      glass.style.left = img.offsetLeft + img.clientWidth + 12 + "px";
      //glass.style.top = y - h + "px";
      /*display what the magnifier glass "sees":*/
      glass.style.backgroundPosition =
        "-" + (x * zoom - w + bw) + "px -" + (y * zoom - h + bw) + "px";
    }
    function hideMagnifier(e: any) {
      glass.style.display = "none"
    }
    function showMagnifier(e: any) {
      glass.style.display = "block"
    }
    function getCursorPos(e: any) {
      var a,
        x = 0,
        y = 0;
      e = e || window.event;
      /*get the x and y positions of the image:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x and y coordinates, relative to the image:*/
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }
    glass.style.display = "none"
  }

  getRelatedProducts(packageId: string) {
    this.categoryService.getRelatedProducts(packageId).subscribe((res: any) => {
      console.log("getRelatedProducts :: ", res.ProductsApiReponse);
      this.relatedProducts = res.ProductsApiReponse.Product;
    }, (err: any) => {
      console.log(err);
    })
  }

  getProductDetailsMaster() {
    const latData = localStorage.getItem('lat');
    const longData = localStorage.getItem('long');
    this.farmerId = localStorage.getItem('FarmerId');
    this.mobileNo = localStorage.getItem('farmerMob');
    this.districtId = localStorage.getItem('districtId');
    console.log("getProductDetailsMaster :: ", this.productId)
    if (this.productId) {

      if ((this.isManualLocation == false) && !this.districtId) {
        if (latData && longData && !this.farmerId) {
          console.log('Option 1')
          this.getProductDetail(this.productId, 0, latData, longData);
        }
        if (this.farmerId && latData && longData) {
          console.log('Option 2')
          this.getProductDetailWithFarmerId(this.productId, 0, this.farmerId, latData, longData);
        }
      } else {
        if (this.districtId && !this.farmerId) {
          console.log('Option 3')
          this.getProductDetail(this.productId, this.districtId, 0, 0);
        }
        if (this.farmerId && this.districtId) {
          console.log('Option 4')
          this.getProductDetailWithFarmerId(this.productId, this.districtId, this.farmerId, 0, 0);
        }
      }
    }
  }



  productsImages: any = [];
  selectedIndex: any = 0
  bigImage: any = ""
  getProductDetail(ProductId: any, Districtid: number, lat: string | number, lon: string | number) {
    this.categoryService.getProductDetails(ProductId, Districtid, lat, lon).subscribe((res: any) => {
      if (res.Status) {
        if (res.BZApiReponse) {
          this.packageId = res.BZApiReponse.ProductDetails[0].PackageID;
          if (this.packageId !== undefined) {
            this.getRelatedProducts(this.packageId);
          }
          this.productDetails = JSON.parse(JSON.stringify(res.BZApiReponse.ProductDetails || null));
          console.log("PRODUCT_DETAILS 1 :: ", this.productDetails)
          this.productsImages = JSON.parse(JSON.stringify(res.BZApiReponse.ImageUrlPath || []));
          this.dealerDetails = JSON.parse(JSON.stringify(res.BZApiReponse.DealerDetails || null));
          this.productAvailability = true;
          if (this.productDetails[0].VideoUrl == null || (this.productDetails[0].VideoUrl == 'NULL')) {
            this.bigImage = this.productsImages[0].ImagePath;
            setTimeout((x: any) => {
              this.magnify("myimage", 2);
            }, 100)
            this.selectedIndex = 0;
            this.imageViewThumb = true;
          } else {
            this.selectedIndex = null
          }
          if (this.productDetails.length > 0) {
            let data: any[] = [];
            this.productDetails.forEach((element: { Description: any; }) => {
              data.push(element.Description);
              return data;
            });
            if (data[0]) {
              for (const [key, value] of Object.entries(JSON.parse(data[0]))) {
                if (value && value !== 'null' && value !== 'NULL') {
                  this.desc.push({ key: key, value: value });
                }
              }
            }
          } else {
            this.productAvailability = false;
          }
          if (this.dealerDetails.length > 0) {

            if (this.state == 'HARYANA') {
              let isDelivery = this.dealerDetails.some((item: any) => item.IsDeliveryAvailable == 1)
              if (isDelivery) this.globalBuyWrapper = false;
            } else if (this.state !== 'HARYANA') {
              //change by prashant
              let isSelfDealerV1 = this.dealerDetails.filter((item: any) => item.IsDeliveryAvailable == 1);
              if (isSelfDealerV1 != null && isSelfDealerV1.length > 0) {
                this.globalBuyWrapper = false;

              }
              else {
                let isSelfDealer = this.dealerDetails.filter((item: any) => item.IsDeliveryAvailable == 2);
                if (Object.keys(isSelfDealer).length) this.dealerID = isSelfDealer[0].dealerId;
              }
              //end change

            }
          } else {
            if (this.state == 'HARYANA') {
              if (this.productDetails[0].IsDeliveryAvailable == 1) {
                this.globalBuyWrapper = false;
              } else {
                this.globalBuyWrapper = true;
                //only show enquiry button
                this.dealersAvailability = false;
              }
            } else {
              this.globalBuyWrapper = true;
              this.dealersAvailability = false;
              //only show msg with enquiry
              this.dealersMessage = res.MSG[0].Msg;
            }
          }
        }
      }


    }, (err: any) => {
      console.log(err);
    })
  }

  getProductDetailWithFarmerId(ProductId: any, Districtid: number, FarmerId: any, lat: string | number, lon: string | number) {
    this.categoryService.getProductDetailsWithFarmerId(ProductId, Districtid, FarmerId, lat, lon).subscribe((res: any) => {
      if (res.Status) {
        if (res.BZApiReponse) {
          this.packageId = res.BZApiReponse.ProductDetails[0].PackageID;
          if (this.packageId !== undefined) {
            this.getRelatedProducts(this.packageId);
          }
          this.productDetails = JSON.parse(JSON.stringify(res.BZApiReponse.ProductDetails || null));
          console.log("PRODUCT_DETAILS 2 :: ", this.productDetails)
          this.productsImages = JSON.parse(JSON.stringify(res.BZApiReponse.ImageUrlPath || []));
          this.dealerDetails = JSON.parse(JSON.stringify(res.BZApiReponse.DealerDetails || null));
          this.productAvailability = true;
          if (this.productDetails[0].VideoUrl == null || (this.productDetails[0].VideoUrl == 'NULL')) {
            this.bigImage = this.productsImages[0]?.ImagePath;

            setTimeout((x: any) => {
              this.magnify("myimage", 2);
            }, 100)
            this.selectedIndex = 0;
            this.imageViewThumb = true;
          } else {
            this.selectedIndex = null
          }
          if (this.productDetails.length > 0) {
            let data: any[] = [];
            this.productDetails.forEach((element: { Description: any; }) => {
              data.push(element.Description);
              return data;
            });

            if (data[0]) {
              for (const [key, value] of Object.entries(JSON.parse(data[0]))) {
                if (value && value !== 'null' && value !== 'NULL') {
                  this.desc.push({ key: key, value: value });
                }
              }
            }
          } else {
            this.productAvailability = false;
          }
          if (this.dealerDetails.length > 0) {
            if (this.state == 'HARYANA') {
              let isDelivery = this.dealerDetails.some((item: any) => item.IsDeliveryAvailable == 1)
              if (isDelivery) {
                this.globalBuyWrapper = false;
              }
              if (this.globalBuyWrapper) {
                if (this.productDetails[0].IsDeliveryAvailable == 1) {
                  this.globalBuyWrapper = false;
                }
              }
            } else if (this.state !== 'HARYANA') {
              let isSelfDealer = this.dealerDetails.filter((item: any) => item.IsDeliveryAvailable == 2);
              if (Object.keys(isSelfDealer).length) this.dealerID = isSelfDealer[0].dealerId;
              let isSelfDealerGBN = this.dealerDetails.filter((item: any) => item.IsDeliveryAvailable == 1);
              if (Object.keys(isSelfDealerGBN).length) {
                //prashant rnd changes
                this.globalBuyWrapper = false;
                this.dealersAvailability = false;
              }
            }
          } else {
            if (this.state == 'HARYANA') {
              if (this.productDetails[0].IsDeliveryAvailable == 1) {
                this.globalBuyWrapper = false;
              } else {
                this.globalBuyWrapper = true;
                this.dealersAvailability = false;
              }
            } else {
              this.globalBuyWrapper = true;
              this.dealersAvailability = false
              this.dealersMessage = res.MSG[0].Msg;
            }
          }
        }
      }
    }, (err: any) => {
      console.log(err);
    })
  }


  openModal(item: { RecordId: any; dealerId: any; }) {
    if (this.farmerId == null) this.login();
    if (this.farmerId) {
      this.categoryService.getBestDeal(item.RecordId, this.mobileNo, this.farmerId, item.dealerId).subscribe((res: any) => {
        if (res.Status) {
          this.dialog.open(DialogComponent, {
            data: res.Msg[0].Msg
          });
        }
      })
    }
  }

  globalBuyBtn() {
    this.farmerId = localStorage.getItem('FarmerId');
    const third_party_flag = sessionStorage.getItem('third_party_flag');
    const userRole = localStorage.getItem('UserRole');
    if (this.farmerId == null && (third_party_flag == null || third_party_flag == undefined || third_party_flag == 'undefined')) {
      this.login();
    }
    else if (this.farmerId == null && third_party_flag !== null && third_party_flag !== undefined && third_party_flag !== 'undefined') {
      this.thirdPartyUser();
    }
    else if (userRole == "Affiliate") {
      this.thirdPartyUser();
    }
    else {
      if (this.farmerId) {
        if (this.dealerID) {
          this.router.navigate(['/bz/checkout', this.productId], { queryParams: { dealerid: this.dealerID, category: this.ItemCategoryTitle } });
        }
        else {
          this.router.navigate(['/bz/checkout', this.productId], { queryParams: { category: this.ItemCategoryTitle } });
        }
      } else {
        this.login();
      }
    }

  }

  thirdPartyUser() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = {
      "callingFrom": "Farmer's Mobile Number",
      "id": 0,
      "typeId": 0,
      "location": ""
    };
    const dialogRef = this.dialog.open(ThirdPartyComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // const tempDetails = localStorage.getItem('farmer_detail_wol');
      console.log("RESULT :: ", result, result == false)
      if (result == false) {
        this.router.navigate(['/']);
      }
      else {
        if (this.dealerID) {
          this.router.navigate(['/bz/checkout', this.productId], { queryParams: { dealerid: this.dealerID, category: this.ItemCategoryTitle } });
        }
        else {
          this.router.navigate(['/bz/checkout', this.productId], { queryParams: { category: this.ItemCategoryTitle } });
        }
      }

      console.log(`Dialog result: ${result}`);
    });
  }

  goToBack() {
    this.location.back();
  }

  changeImage(imgPath: any, index: number) {
    this.imageViewThumb = true;
    this.bigImage = imgPath;
    this.selectedIndex = index;
    //debugger
    setTimeout((x: any) => {
      this.magnify("myimage", 2);
    }, 100)

  }

  hideVideo() {
    this.imageViewThumb = true;
    this.selectedIndex = 0;
    this.bigImage = this.productsImages[0]?.ImagePath
    setTimeout((x: any) => {
      this.magnify("myimage", 2);
    }, 100)
  }

  login() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      "callingFrom": "Login",
      "id": 0,
      "typeId": 0,
      "location": ""
    };
    const dialogRef = this.dialog.open(LoginComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (this.dealerID) {
        this.router.navigate(['/bz/checkout', this.productId], { queryParams: { dealerid: this.dealerID, category: this.ItemCategoryTitle } });
      }
      else {
        this.router.navigate(['/bz/checkout', this.productId], { queryParams: { category: this.ItemCategoryTitle } });
      }
      console.log(`Dialog result: ${result}`);
    });
  }
  matchYoutubeUrl(url: { match: (arg0: RegExp) => any[]; }) {
    console.log('matchYoutubeUrl :: ', url)
    if (!url) this.selectedIndex = 0;
    var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(p)) {
      return url.match(p)[1];
    }
    return false;
  }

  afterChange(e: any) {
    if (!this.loopComplete) {
      this.loopComplete = (this.currentSlideIndex == this.relatedProducts.length) ? true : false;
      this.currentSlideIndex = this.currentSlideIndex + 1;
    }
  }

  /**GO TO DETAILS PAGE**/
  goToDetailPage(id: any, category: any, name: any) {
    if (this.latLong) {
      localStorage.setItem('latData', this.latLong.lat);
      localStorage.setItem('longData', this.latLong.long);
    }
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/bz/product-detail', id], { queryParams: { category: category, name: name } })
    )
}

notifyMe(packageID: any, recordID: any) {
  const farmerID = localStorage.getItem("FarmerId");
  this.checkoutService.notifyProduct(packageID, recordID, farmerID).subscribe((res: any) => {
    this.toastr.success(res.BZReponse.SaveOutofStockNotification[0].Msg, 'Success!!');
  }, (err: any) => {
    console.log(err);
  })
}
}
