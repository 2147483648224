<!-- Subheader Start -->
<div class="andro_subheader pattern-bg primary-bg">
    <div class="container">
        <div class="andro_subheader-inner">
            <h1></h1>
            <nav aria-label="breadcrumb" class="mt-3">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">About Us</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<!-- Img Start -->
<div class="section">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-12">
                <div class="section-title-wrap mr-lg-30">                   
<p>
    “BZ is India's largest agri-marketplace offering Livestock feed,
    Agri-Inputs, harvesting machineries &amp; tractors, with a unique network
    of H2O™ Network (Hyperlocal Human support Offering). We are an internet
    company with a goal to offer all farming needs in a single-window by
    bringing the widest range of sellers to our platform.” With a future-ready
    transparent tech platform, it attracts the key agri-sellers, advisory
    experts, tools and machinery manufacturers, financial institutions, and
    innovative startups, to come under a single-window for solving all farming
    needs.” In other words, BZ is a technology platform that offers: 1.
    Discovery of all farming needs (bringing the widest selection); 2.
    Accessibility of what they discover; (last mile, digital reach) 3. Return
    on investment (financially rewarding).<strong></strong>
</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Img End -->

<!-- Img Start -->
<div class="section pt-0">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-12">
                <div class="section-title-wrap mr-lg-30">
                    <h4 class="title text-center">Our Core Team</h4>
                    <div class="row">
                        <div class="col-md-3 col-sm-6">
                            <div class="team-box">
                                <img src="assets/img/teams/praveen-sir.jpg" alt="team">
                                <h6 class="mb-0 text-center mt-2"><a href="https://www.linkedin.com/in/praveen-rajpal-a8550a51/" target="_blank">Praveen Rajpal</a></h6>
                                <p class="text-center mb-0">CEO</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                            <div class="team-box">
                                <img src="assets/img/teams/Dips.jpg" alt="team" style="max-width: 56%;">
                                <h6 class="mb-0 text-center mt-2"><a href="https://www.linkedin.com/in/dipanker-mukherjee/" target="_blank">Dipanker Mukherjee</a></h6>
                                <p class="text-center mb-0">Advisory Board Member</p>
                            </div>
                        </div>
                        <!-- <div class="col-md-3 col-sm-6">
                            <div class="team-box">
                                <img src="assets/img/teams/Rishi.jpg" alt="team">
                                <h6 class="mb-0 text-center mt-2"><a href="https://www.linkedin.com/in/rishimjha/" target="_blank">Rishi Jha</a></h6>
                                <p class="text-center mb-0">Co-Founder, Product Mentor</p>
                            </div>
                        </div> -->
                        <!-- <div class="col-md-3 col-sm-6">
                            <div class="team-box">
                                <img src="assets/img/teams/Saurabh-Gupta.jpg" alt="team">
                                <h6 class="mb-0 text-center mt-2"><a href="https://www.linkedin.com/in/saurabh-gupta-03a3661b/" target="_blank">Saurabh Gupta</a></h6>
                                <p class="text-center mb-0">CFO</p>
                            </div>
                        </div> -->
                        <!-- <div class="col-md-3 col-sm-6">
                            <div class="team-box">
                                <img src="assets/img/teams/Gaurav.jpg" alt="team">
                                <h6 class="mb-0 text-center mt-2"><a href="https://www.linkedin.com/in/gaurav-dutta-2a869b22/" target="_blank">Gaurav Datta</a></h6>
                                <p class="text-center mb-0">Head - Alliance</p>
                            </div>
                        </div> -->
                        <div class="col-md-3 col-sm-6">
                            <div class="team-box">
                                <img src="assets/img/teams/Shekhar1.jpg" alt="team">
                                <h6 class="mb-0 text-center mt-2"><a href="https://www.linkedin.com/in/shekhar-chandran-36826225" target="_blank">Shekhar Chandran</a></h6>
                                <p class="text-center mb-0">Head - Digital Marketing</p>
                            </div>
                        </div>
                        <!-- <div class="col-md-3 col-sm-6">
                            <div class="team-box">
                                <img src="assets/img/teams/geetika.jpg" alt="team">
                                <h6 class="mb-0 text-center mt-2"><a href="https://www.linkedin.com/in/geetika-bahl-a7822252/" target="_blank">Geetika Bahl</a></h6>
                                <p class="text-center mb-0">Head - Human Resource</p>
                            </div>
                        </div> -->
                        <!-- <div class="col-md-3 col-sm-6">
                            <div class="team-box">
                                <img src="assets/img/teams/kunal.jpg" alt="team">
                                <h6 class="mb-0 text-center mt-2"><a href="https://www.linkedin.com/in/kunal-parmar-1a6a1a185/" target="_blank">Kunal Parmar</a></h6>
                                <p class="text-center mb-0">Head - Farm Service Centre</p>
                            </div>
                        </div> -->
                        <div class="col-md-3 col-sm-6">
                            <div class="team-box">
                                <img src="assets/img/teams/Varun-Sharma.jpg" alt="team">
                                <h6 class="mb-0 text-center mt-2"><a href="https://www.linkedin.com/in/varun-sharma-6b50951b5/" target="_blank">Varun Sharma</a></h6>
                                <p class="text-center mb-0">Senior Software Engineer</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                            <div class="team-box">
                                <img src="assets/img/teams/Asim.jpg" alt="team">
                                <h6 class="mb-0 text-center mt-2"><a href="javascript:;" target="_blank">Md. Asim</a></h6>
                                <p class="text-center mb-0">Senior Software Engineer</p>
                            </div>
                        </div>   
                        <div class="col-md-3 col-sm-6">
                            <div class="team-box">
                                <img src="assets/img/teams/deepak.jpg" alt="team">
                                <h6 class="mb-0 text-center mt-2"><a href="" target="_blank">Deepak Kamboj</a></h6>
                                <p class="text-center mb-0"> Manager IT & Cloud Administration</p>
                            </div>
                        </div>                   
                        <div class="col-md-3 col-sm-6">
                            <div class="team-box">
                                <img src="assets/img/teams/Praveen.jpg" alt="team">
                                <h6 class="mb-0 text-center mt-2"><a href="https://www.linkedin.com/in/praveen-kumar-90435b18a/" target="_blank">Praveen Kumar</a></h6>
                                <p class="text-center mb-0">Category Manager (Product Sourcing)</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                            <div class="team-box">
                                <img src="assets/img/teams/Saurabh-Jain.jpg" alt="team">
                                <h6 class="mb-0 text-center mt-2"><a href="https://www.linkedin.com/in/saurabh-jain-0753941a/" target="_blank">Saurabh Jain</a></h6>
                                <p class="text-center mb-0">Assistant Manager (Operations)</p>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="section pt-0">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-12">
                <div class="section-title-wrap mr-lg-30">
                    <h4 class="title text-center">Fast Facts</h4>
                    <div class="row">
                        <div class="col-md-4 col-sm-6">
                            <div class="facts-box text-center">
                                <h4 class="mb-0">500000+ <br/>Farmers</h4>
                                <p>farmers connected with Behtar Zindagi.</p>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="facts-box text-center">
                                <h4 class="mb-0">10000+ <br/>Products</h4>
                                <p>India’s biggest Agri-Marketplace.</p>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="facts-box text-center">
                                <h4 class="mb-0">1000+ <br/>Sellers</h4>
                                <p>Satisfied sellers working with us.</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Img End -->
<!-- Testimonials Start -->
<div class="section section-padding pt-0">
    <div class="container">
        <div class="">
            <h4 class="title text-center">What Are People Saying</h4>
        </div>
        <div class="row">
            <!-- Testimonail item start -->
            <div class="col-lg-4 col-md-6">
                <div class="andro_testimonial">
                    <div class="andro_testimonial-body">
                        <h5>Quality Products at Best Price</h5>
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                            </div>
                        </div>
                        <p>बेहतर ज़िंदगी सेवा का उपयोग करके मैं बिल्कुल संतुष्ट हूँ , घर बैठ सभी कृषि उत्पाद और सही कृषि सलाह मिल जाता है</p>
                    </div>
                    <div class="andro_testimonial-author">
                        <a href="https://www.youtube.com/watch?v=E7aPGkidMMM&t=29s" target="_blank"><img src="assets/img/user.jpg" alt="testimonial"></a>
                        <div class="andro_testimonial-author-inner">
                            <a href="https://www.youtube.com/watch?v=E7aPGkidMMM&t=29s" target="_blank">
                            <h6>सोहन सरपंच</h6>
                            <span>सिरसा, हरियाणा</span>
                        </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Testimonail item end -->
            <!-- Testimonail item start -->
            <div class="col-lg-4 col-md-6">
                <div class="andro_testimonial">
                    <div class="andro_testimonial-body">
                        <h5>Superfast Delivery</h5>
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                            </div>
                        </div>
                        <p>मैं बेहतर ज़िंदगी सेवा से पिछले 2 साल से जुड़ा हूँ और सभी कृषि उत्पाद यही से मँगवाता हूँ, यहा से ओरिजिनल कृषि उत्पाद पक्के बिल के साथ 24-48 घंटो के अंदर घर पर मिल जाता है</p>
                    </div>
                    <div class="andro_testimonial-author">
                        <a href="https://www.youtube.com/watch?v=uF4FJTCjrHI&t=1s" target="_blank"><img src="assets/img/user.jpg" alt="testimonial"></a>
                        <div class="andro_testimonial-author-inner">
                            <a href="https://www.youtube.com/watch?v=uF4FJTCjrHI&t=1s" target="_blank">
                                <h6>रणवीर सिंह</h6>
                                <span>जींद, हरियाणा</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Testimonail item end -->
            <!-- Testimonail item start -->
            <div class="col-lg-4 col-md-12">
                <div class="andro_testimonial">
                    <div class="andro_testimonial-body">
                        <h5>Great Service for Farmers</h5>
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                            </div>
                        </div>
                        <p>मैं पिछले 2 साल से बेहतर ज़िंदगी सेवा से जुड़ा हूँ और पशु आहार एवं कृषि उत्पाद दोनो मँगवाता हूँ. सभी उत्पाद उच्च क्वालिटी की और पक्के बिल के साथ 24-48 घंटो में घर पर मिल जाती है</p>
                    </div>
                    <div class="andro_testimonial-author">
                        <a href="https://www.youtube.com/watch?v=RBavFXCCSEg" target="_blank"><img src="assets/img/user.jpg" alt="testimonial"></a>
                        <div class="andro_testimonial-author-inner">
                            <a href="https://www.youtube.com/watch?v=RBavFXCCSEg" target="_blank">
                                <h6>प्रदीप कुमार</h6>
                                <span>जींद, हरियाणा</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Testimonail item end -->
        </div>
    </div>
</div>
<!-- Testimonials End -->