<!-- Subheader Start -->
<div class="andro_subheader pattern-bgdp primary-bg">
    <div class="container">
        <div class="andro_subheader-inner">
            <h1></h1>
            <nav aria-label="breadcrumb" class="mt-3">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Delivery Policy</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<!-- Img Start -->
<div class="section">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-12">
                <div class="section-title-wrap mr-lg-30">
                    <p>
                        <strong class="custom-10">DELIVERY POLICY</strong>
                    </p>
                    <p>
                        <strong>
                    ** YOUR ORDER SHOULD BE DELIVERED WITHIN <em>5</em>-<em>7</em> WORKING        <em>DAYS </em>**
                        </strong>
                    </p>
                    <p>
                        If delivery or performance deadlines are not met for reasons beyond our
                        control, such as force majeure, war, terrorist attacks, import or export
                        restrictions, labour disputes, or if the aforementioned affects our
                        component suppliers, the agreed deadlines shall be extended for a
                        reasonable period, even if at the time we are in default.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Img End -->
