<app-profile hidden></app-profile>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
  <!-- Subheader Start -->
  <div class="container-fluid mt-2">
    <div class="section-title flex-title pb-0">
      <h4 class="title float-left">Checkout</h4>
      <nav aria-label="breadcrumb" class="float-right">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0)" [routerLink]="['/']">Home</a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0)" (click)="goToBack()">Product Details</a></li>
          <li class="breadcrumb-item active" aria-current="page">Checkout page</li>
        </ol>
      </nav>
    </div>
  </div>

  <!-- Subheader End -->
  <!-- Product Content Start -->
  <div class="productWrapper">
    <div class="container">
      <ng-container *ngIf="!productDetails.length">
        <div class="container-fluid">
          <div class="loading">
            <img src="./assets/img/loading.gif" alt="loading">
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="productDetails.length">
        <div class="row" *ngFor="let item of productDetails">
          <div class="col-md-3">
            <div class="andro_product-single-thumb">
              <img [src]="item?.ImageUrl" alt="{{item?.ProductName}}" class="">
            </div>
          </div>
          <div class="col-md-9">
            <div class="andro_product_content">
              <h3> {{item?.ProductName}} </h3>
              <div class="andro_product-price mb-0">
                <p><strong>Quantity:</strong> &nbsp; {{totalQuantity}}
                  <select class="form-control col-sm-2" (change)="selectedValue($event.target)">
                    <option *ngFor="let item of quantity" [value]="item">
                      {{item}}
                    </option>
                  </select>
                </p>
                <p><strong>COD Price:</strong>&nbsp;<b>&#8377; {{totalAmount-discountAmount}}</b>&nbsp;<del
                    *ngIf="discountPriceToggle">&#8377; {{totalAmount}}</del></p>
                <div *ngIf="userRole !== 'Affiliate'" class="w-100 d-table mb-2"><strong>Address: </strong> <input type="text"
                    class="form-control col-md-9" placeholder="Enter full address" [(ngModel)]="addressValue">
                </div>
                <div class="row">
                  <div class="col-md-9">
                    <strong>Coupon Code: </strong>
                    <div class="row">
                      <div class="col-md-9 col-9">
                        <input type="text" class="form-control" [disabled]="isCouponApplied" placeholder="Coupon code"
                          [(ngModel)]="couponValue">
                      </div>
                      <div class="col-md-3 col-3 ">
                        <button type="button" [disabled]="isCouponApplied" class="btn btn-primary"
                          (click)="validateCoupon()">Apply</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="radio_group_row radio_group_abs1">

                  <mat-radio-group [ngModel]="paymentmode" [ngModelOptions]="{standalone: true}" name="paymentmode"
                    aria-label="option" color="primary">
                    <mat-radio-button [checked]="paymentmode === 'online_payment'" (click)="changePaymentmode('online_payment')" value="online_payment">Pay Online
                    </mat-radio-button>
                    <mat-radio-button [checked]="paymentmode === 'COD'" (click)="changePaymentmode('COD')" value="COD">Cash on delivery</mat-radio-button>
                  </mat-radio-group>
                </div>

                <div class="radio_group_row radio_group_abs2">
                  <mat-radio-group [(ngModel)]="paymentmode" color="primary">
                    <mat-radio-button value="online_payment">Pay Online</mat-radio-button>
                    <mat-radio-button value="COD">Cash on delivery</mat-radio-button>
                  </mat-radio-group>
                </div>



                <div class="row" *ngIf="affiliateToggle">
                  <div class="col-md-9">
                    <strong>Affiliate UserId: </strong>
                    <div class="row">
                      <div class="col-md-9 col-9">
                        <input type="text" class="form-control" placeholder="Affiliate code"
                          [(ngModel)]="affiliatevalue">
                      </div>
                      <!-- <div class="col-md-3 col-3 ">
                      <button type="button" [disabled]="isCouponApplied" class="btn btn-primary"
                        (click)="affiliateApply()">Apply</button>
                    </div> -->
                    </div>
                  </div>
                </div>
                <p><button type="button" class="btn btn-primary mt-2" (click)="checkout()">Place Order</button></p>
                <!-- <p><button type="button" class="btn btn-primary mt-2" (click)="customerPayReq()">Customer Pay request</button></p> -->
                <!-- <p><button type="button" class="btn btn-primary mt-2" (click)="complete()">complete</button></p> -->
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <!-- Product Content End -->
